import type { UIMediaProps } from '@design-system/components/UI/UIMedia.props'

export function transformMedia(rawMedia: UIMediaProps) {
  if (rawMedia.media.type === 'image') {
    return {
      media: {
        type: 'image',
        srcImage: {
          secure_url: rawMedia.media.srcImage.secure_url,
          public_id: rawMedia.media.srcImage.public_id,
        },
        alt: rawMedia.media.alt,
        sizes: rawMedia.media.sizes,
      },
      aspectRatio: rawMedia.aspectRatio,
    }
  } else if (rawMedia.media.type === 'video') {
    return {
      media: {
        type: 'video',
        srcVideo: {
          secure_url: rawMedia.media.srcVideo.secure_url,
          public_id: rawMedia.media.srcVideo.public_id,
        },
        autoplay: rawMedia.media.autoplay,
        muted: rawMedia.media.muted,
        showCommands: rawMedia.media.showCommands,
        commandsColor: rawMedia.media.commandsColor,
        commandsPosition: rawMedia.media.commandsPosition,
        coverImage: rawMedia.media.coverImage,
        subtitle: rawMedia.media.subtitle,
        quality: rawMedia.media.quality,
      },
      aspectRatio: rawMedia.aspectRatio,
    }
  }
  throw new Error('Unsupported media type')
}
